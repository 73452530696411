import store from '~/store';
import { apiGetTags } from '~/api/app';
import { apiGetMeta } from '~/api/user';
import { USER_META } from '~/const';
import { getNotifications } from '~/store/slices/notification/reducers';
import { getCalendar } from '~/store/slices/calendar/reducers';
import { setUserListOptions } from '~/store/slices/userList/slice';
import { setTags } from '~/store/slices/app/slice';
import { setProjectListOptions } from '~/store/slices/projectList/slice';

export const prepareApp = async () => {
    // TODO: сейчас запросы кидаются последовательно, нужно сделать параллельные одновременные запросы (те, которые не зависят от предыдущих)
    store.dispatch(getNotifications());
    store.dispatch(getCalendar());

    // TODO: не уверен что полный список тегов вообще нужно подгружать на фронт...
    const localTags = localStorage.getItem('tags');
    if (!localTags) {
        await apiGetTags().then((res) => {
            const tags = res.filter((tag: ITag) => tag.name.length > 0);
            store.dispatch(setTags(tags));
        });
    } else {
        store.dispatch(setTags(JSON.parse(localTags)));
        apiGetTags().then((res) => {
            const tags = res.filter((tag: ITag) => tag.name.length > 0);
            store.dispatch(setTags(tags));
        });
    }

    // TODO: добавить в me
    await apiGetMeta(USER_META.PROJECTS_OPTIONS).then((res) => {
        store.dispatch(setProjectListOptions(res[USER_META.PROJECTS_OPTIONS]));
    });

    // TODO: добавить в me
    await apiGetMeta(USER_META.USERS_OPTIONS).then((res) => {
        store.dispatch(setUserListOptions(res[USER_META.USERS_OPTIONS]));
    });
};
